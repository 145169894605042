.header-img {
  height: 80px;
  width: 100%;
  background: #4b2e83;
  position: relative;
  z-index: 2;
  padding-top: 7px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.nav-bar {
  width: 100%;
}

.image {
  height: 70px;
}

.form-heading {
  text-align: center;
  margin-top: 10px;
}
