.spinning {
  margin-left: 120px;
}

.tbl-stu-info {
  margin-top: 5px;
  border: groove;
}

.qstn-lbl {
  font-weight: bold;
}

.save-btn {
  border-color: #e8e3d3;
  background-color: #e8e3d3;
  color: #4b2e83;
}

.save-btn:hover {
  border-color: #e8e3d3;
  background-color: #e8e3d3;
  color: #4b2e83;
}

.save-btn:active {
  border-color: #e8e3d3;
  background-color: #e8e3d3;
  color: #4b2e83;
}

.logout-btn {
  margin-left: 30px;
}

.switch-btn {
  font-weight: bold;
  #toggle-switch:checked {
    accent-color: #4b2e83;
    background-color: #4b2e83;
    border-color: #4b2e83;
  }
}

.radio-btn {
  #select-listing:checked {
    accent-color: #4b2e83;
    background-color: #4b2e83;
    border-color: #4b2e83;
  }
}

.main-container {
  display: flex;
  justify-content: center;
}
