.main-container {
  display: flex;
  justify-content: center;
}

.LogInTxt {
  margin-left: 5px;
}

.login-btn {
  margin-left: 10px;
  border-color: #e8e3d3;
  background-color: #e8e3d3;
  color: #4b2e83;
  width: 50%;
}

.login-btn:hover {
  border-color: #e8e3d3;
  background-color: #e8e3d3;
  color: #4b2e83;
}

.login-btn:active {
  border-color: #e8e3d3;
  background-color: #e8e3d3;
  color: #4b2e83;
}
